@use '@angular/material' as mat;

// Material core styles
@include mat.core(); // This will load the core theme styles required for Material components

// Example of namespacing custom styles
.app-container {

  // Global body styling
  html, body {
    display: flex;
    flex-direction: column;
    background: url("/assets/images/misc/background-runin.jpg") no-repeat fixed !important;
    background-size: cover; /* This ensures the image covers the whole area */
  }

  .bg-grey {
    background-color: #696969;
  }
  .bg-darknavyblue{
    background-color: #222f3d;
  }

  .opake-small {
    opacity: 0.6;
    font-size: small;
  }

  .opake-medium {
    opacity: 0.6;
    font-size: medium;
  }

  .opake-large {
    opacity: 0.6;
    font-size: large;
  }

  main {
    flex: 1 0 auto;
  }

  .title {
    color: white;
    background-color: #222f3d;
    text-transform: uppercase;
    text-align: center;
  }

  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #343a40;
    color: #fff;
    padding: 5px;
  }

  // Image styling
  img {
    border-radius: 10px;
  }

  // Container for images with centralized content
  .img-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  // Form element styling (kept minimal to avoid conflicts)
  input,
  select,
  textarea {
    max-width: 240px;
  }

  // Table styling VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
  table {
    width: 50%;
    border-collapse: collapse;
    tr,
    th,
    td {
      padding: 5px;
      border: 1px solid black;
    }
  }
}
.mat-header-cell.tableheader {
  color: white;
  background-color: #222f3d;
  text-transform: uppercase;
  text-align: center;
}

.mat-mdc-cell {
  color: #fff;
  font-size: medium;
  text-align: center;
  vertical-align: middle;
}

.mat-row:nth-child(odd) {
  background-color: #f2f2f2;
  /* Light grey for odd rows */
}

.mat-row:nth-child(even) {
  background-color: #ffffff;
  /* White for even rows */
}

.mat-mdc-row:nth-child(odd) {
  background-color: #696969;
}

.mat-mdc-row:nth-child(even) {
  background-color: rgb(89, 89, 89);
}

// Table styling ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

a {
  color: #fff;
  text-decoration: underline
}